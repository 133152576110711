/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

#root {
  zoom: 1;
  height: 100%;
}

@media screen and (max-width: 640px) {
  #root {
    zoom: 0.8;
  }
}
